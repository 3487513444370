<template>
  <div>


    <!-- End Filter -->
    <div class="card card-custom">
      <div class="card-body">
        <div class="row mb-5">

          <div class="form-group col-md-4 mt-2 mb-2">
            <label>{{ $t('tax_report.branch') }}</label>
            <multiselect v-model="branches_ids"
                         :placeholder="$t('tax_report.branch')"
                         label="name"
                         track-by="id"
                         :options="branches"
                         :multiple="false"
                         @input="getIds('branches')"
                         :taggable="false"
                         :show-labels="false"
                         :show-no-options="false"
                         :show-no-results="false">
            </multiselect>
          </div>
<!--          <div class="form-group col-md-4 mt-2 mb-2">-->
<!--            <label for="from_date">{{ $t('tax_report.tax') }}</label>-->
<!--            <multiselect v-model="taxes_ids"-->
<!--                         :placeholder="$t('tax_report.tax')"-->
<!--                         label="name"-->
<!--                         track-by="id"-->
<!--                         :options="taxes"-->
<!--                         :multiple="false"-->
<!--                         @input="getIds('taxes')"-->
<!--                         :taggable="false"-->
<!--                         :show-labels="false"-->
<!--                         :show-no-options="false"-->
<!--                         :show-no-results="false">-->
<!--            </multiselect>-->
<!--          </div>-->
          <div class="form-group col-md-4 mt-2 mb-2">
            <label>{{ $t('currency') }}</label>
            <select id="currency_id" v-model="filters.f_currencies_ids" class="custom-select" name="">
              <option v-for="row in currencies" :key="row.id" :value="row.id">{{ row.name }}</option>
            </select>
          </div>

          <div class="form-group col-md-4 mt-2 mb-2">
            <label for="from_date">{{ $t('tax_report.from_date') }}</label>
            <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
          </div>
          <div class="form-group col-md-4 mt-2 mb-2">
            <label for="to_date">{{ $t('tax_report.to_date') }}</label>
            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
          </div>
          <div class="col-md-8 mb-2 pt-10 d-flex">
            <div>
              <b-button-group>
                <b-button @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_date') }}</b-button>
                <b-button @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_month') }}</b-button>
                <b-button @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_year') }}</b-button>
                <b-button @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.financial_year') }}</b-button>
                <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
              </b-button-group>

            </div>
            <div class="mx-2">
              <b-button-group>
                <b-button @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.yesterday') }}</b-button>
                <b-button @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_month') }}</b-button>
                <b-button @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_year') }}</b-button>
              </b-button-group>

            </div>
          </div>


          <div class="form-group d-inline-flex col-md-6 mt-2 mb-0">
            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
              {{ $t('display_report') }}
            </button>
            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
              <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
            </button>
          </div>
        </div>
        <div class="mt-3">
          <div class="row mb-10">
            <div class="col-12">
                  <div class="table-responsive">
                    <div  class="row justify-content-end p-4">
                      <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                        <button class="dropdown-item" @click="convertTableToExcelReport('sectionForExportDetails')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                        </button>

                        <button class="dropdown-item" @click="printData('sectionForExportDetails')">
                          <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                        </button>

                      </b-dropdown>
                    </div>
                    <table class="table table-bordered" id="DetailsTable">
                      <tbody>
                      <!---------------------Sales------------------------->
                      <tr class="text-center font-weight-bold">
                        <td class="text-0C6F58">{{ $t('tax_report.value_added_tax_on_sales') }}</td>
                        <td>{{ $t('tax_report.amount') }} (SAR)</td>
                        <td>{{ $t('tax_report.adjustment_amount') }} (SAR)</td>
                        <td>{{ $t('tax_report.vat_amount') }} (SAR)</td>
                      </tr>

                      <tr>
                        <td>1- {{ $t('tax_report.sales_subject_to_basic_rate') }}</td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center"
                                   :value="sales.sales_subject_to_basic_rate.invoice_total_after_tax ? sales.sales_subject_to_basic_rate.invoice_total_after_tax : 0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center"
                                   :value="sales.sales_subject_to_basic_rate.credit_invoice_total_after_tax ? sales.sales_subject_to_basic_rate.credit_invoice_total_after_tax : 0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td><input type="text" disabled class="form-control form-control-sm text-center bg-E4A102" :value="sales.sales_subject_to_basic_rate.tax_values? sales.sales_subject_to_basic_rate.tax_values : 0.00"></td>
                      </tr>
                      <tr>
                        <td>2- {{ $t('tax_report.sales_to_citizens') }}</td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center" value="0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center" value="0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>3- {{ $t('tax_report.zero_rated_domestic_sales') }}</td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center"
                                   :value="sales.zero_rated_domestic_sales.invoice_total_after_tax ? sales.zero_rated_domestic_sales.invoice_total_after_tax : 0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center"
                                   :value="sales.zero_rated_domestic_sales.credit_invoice_total_after_tax ? sales.zero_rated_domestic_sales.credit_invoice_total_after_tax : 0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>4- {{ $t('tax_report.exports') }}</td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center" value="0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center" value="0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>5- {{ $t('tax_report.exempt_sales') }}</td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center" value="0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center" value="0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">6- {{ $t('tax_report.total_sales') }}</td>
                        <td><input type="text" disabled class="form-control form-control-sm text-center bg-E4A102" :value="sumAppliedAmountsSales"></td>
                        <td><input type="text" disabled class="form-control form-control-sm text-center bg-E4A102" :value="sumCreditSales"></td>
                        <td><input type="text" disabled class="form-control form-control-sm text-center bg-E4A102" :value="sumTaxSales"></td>
                      </tr>



                      <!---------------------Purchase------------------------->
                      <tr>
                        <td class="font-weight-bold text-center text-0C6F58">{{ $t('tax_report.purchase_tax') }}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>7- {{ $t('tax_report.purchases_subject_to_the_basic_rate') }}</td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center"
                                   :value="purchase.purchase_subject_to_basic_rate.invoice_total_after_tax ? purchase.purchase_subject_to_basic_rate.invoice_total_after_tax : 0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center"
                                   :value="purchase.purchase_subject_to_basic_rate.credit_invoice_total_after_tax ? purchase.purchase_subject_to_basic_rate.credit_invoice_total_after_tax : 0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td><input type="text" disabled class="form-control form-control-sm text-center bg-E4A102" :value="purchase.purchase_subject_to_basic_rate.tax_values ? purchase.purchase_subject_to_basic_rate.tax_values : 0.00"></td>
                      </tr>
                      <tr>
                        <td>8- {{ $t('tax_report.expenses_subject_to_the_basic_rate') }}</td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center" value="0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center" value="0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td><input type="text" disabled class="form-control form-control-sm text-center bg-E4A102" value="0.00"></td>
                      </tr>
                      <tr>
                        <td>9- {{ $t('tax_report.imports_subject_to_vat_at_the_basic_rate_and_paid_at_customs') }}</td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center" value="0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center" value="0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td><input type="text" disabled class="form-control form-control-sm text-center bg-E4A102" value="0.00"></td>
                      </tr>
                      <tr>
                        <td>10- {{ $t('tax_report.imports_subject_to_vat_to_which_the_reverse_charge_mechanism_applies') }}</td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center" value="0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center" value="0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center" value="0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>11- {{ $t('tax_report.zero_rated_purchases') }}</td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center"
                                   :value="purchase.zero_rated_domestic_purchase.invoice_total_after_tax ? purchase.zero_rated_domestic_purchase.invoice_total_after_tax : 0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center"
                                   :value="purchase.zero_rated_domestic_purchase.credit_invoice_total_after_tax ? purchase.zero_rated_domestic_purchase.credit_invoice_total_after_tax : 0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>12- {{ $t('tax_report.exempt_purchases') }}</td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center" value="0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center" value="0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">13- {{ $t('tax_report.total_purchases') }}</td>
                        <td><input type="text" disabled class="form-control form-control-sm text-center bg-E4A102" :value="sumAppliedAmountsPurchase"></td>
                        <td><input type="text" disabled class="form-control form-control-sm text-center bg-E4A102" :value="sumCreditPurchase"></td>
                        <td><input type="text" disabled class="form-control form-control-sm text-center bg-E4A102" :value="sumTaxPurchase"></td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">14- {{ $t('tax_report.total_vat_due_for_the_current_tax_period') }}</td>
                        <td></td>
                        <td></td>
                        <td><input type="text" disabled class="form-control form-control-sm text-center bg-E4A102" value="0.00"></td>
                      </tr>
                      <tr>
                        <td>15- {{ $t('tax_report.corrections_from_previous_periods') }}</td>
                        <td></td>
                        <td></td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center" value="0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>16- {{ $t('tax_report.vat_carried_forward_from_previous_period') }}</td>
                        <td></td>
                        <td></td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center" value="0.00">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">17- {{ $t('tax_report.net_tax_due') }}</td>
                        <td></td>
                        <td></td>
                        <td>
                          <div class="d-flex justify-content-center align-items-center">
                            <input type="text" disabled class="form-control form-control-sm text-center" :value="TotalNetTax">
                            <i class="fas fa-question-circle mx-2"></i>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>


            </div>
          </div>
        </div>

      </div>
    </div>
    <!--end::supplier-->
    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered" id="sectionForExportDetails">
          <tbody>
          <!---------------------Sales------------------------->
          <tr class="text-center font-weight-bold">
            <td class="text-0C6F58">{{ $t('tax_report.value_added_tax_on_sales') }}</td>
            <td>{{ $t('tax_report.amount') }} (SAR)</td>
            <td>{{ $t('tax_report.adjustment_amount') }} (SAR)</td>
            <td>{{ $t('tax_report.vat_amount') }} (SAR)</td>
          </tr>

          <tr>
            <td>1- {{ $t('tax_report.sales_subject_to_basic_rate') }}</td>
            <td>
              <span style="width: 100%;text-align: center;">{{sales.sales_subject_to_basic_rate.invoice_total_after_tax ? sales.sales_subject_to_basic_rate.invoice_total_after_tax : 0.00}}</span>
            </td>
            <td>
              <span style="width: 100%;text-align: center;">{{sales.sales_subject_to_basic_rate.credit_invoice_total_after_tax ? sales.sales_subject_to_basic_rate.credit_invoice_total_after_tax : 0.00}}</span>
            </td>
            <td><span style="width: 100%;text-align: center;">{{sales.sales_subject_to_basic_rate.tax_values ? sales.sales_subject_to_basic_rate.tax_values : 0.00}}</span></td>
          </tr>
          <tr>
            <td>2- {{ $t('tax_report.sales_to_citizens') }}</td>
            <td>
              <span style="width: 100%;text-align: center;">0.00</span>
            </td>
            <td>
              <span style="width: 100%;text-align: center;">0.00</span>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>3- {{ $t('tax_report.zero_rated_domestic_sales') }}</td>
            <td>
              <span style="width: 100%;text-align: center;">{{sales.zero_rated_domestic_sales.invoice_total_after_tax ? sales.zero_rated_domestic_sales.invoice_total_after_tax : 0.00}}</span>
            </td>
            <td>
              <span style="width: 100%;text-align: center;">{{sales.zero_rated_domestic_sales.credit_invoice_total_after_tax ? sales.zero_rated_domestic_sales.credit_invoice_total_after_tax : 0.00}}</span>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>4- {{ $t('tax_report.exports') }}</td>
            <td>
              <span style="width: 100%;text-align: center;">0.00</span>
            </td>
            <td>
              <span style="width: 100%;text-align: center;">0.00</span>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>5- {{ $t('tax_report.exempt_sales') }}</td>
            <td>
              <span style="width: 100%;text-align: center;">0.00</span>
            </td>
            <td>
              <span style="width: 100%;text-align: center;">0.00</span>
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="font-weight-bold">6- {{ $t('tax_report.total_sales') }}</td>
            <td><span style="width: 100%;text-align: center;">{{sumAppliedAmountsSales}}</span></td>
            <td><span style="width: 100%;text-align: center;">{{sumCreditSales}}</span></td>
            <td><span style="width: 100%;text-align: center;">{{sumTaxSales}}</span></td>
          </tr>



          <!---------------------Purchase------------------------->
          <tr>
            <td class="font-weight-bold text-center text-0C6F58">{{ $t('tax_report.purchase_tax') }}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>7- {{ $t('tax_report.purchases_subject_to_the_basic_rate') }}</td>
            <td>
              <span style="width: 100%;text-align: center;">{{purchase.purchase_subject_to_basic_rate.invoice_total_after_tax ? purchase.purchase_subject_to_basic_rate.invoice_total_after_tax : 0.00}}</span>
            </td>
            <td>
              <span style="width: 100%;text-align: center;">{{purchase.purchase_subject_to_basic_rate.credit_invoice_total_after_tax ? purchase.purchase_subject_to_basic_rate.credit_invoice_total_after_tax : 0.00}}</span>
            </td>
            <td><span style="width: 100%;text-align: center;">{{purchase.purchase_subject_to_basic_rate.tax_values ? purchase.purchase_subject_to_basic_rate.tax_values : 0.00}}</span></td>
          </tr>
          <tr>
            <td>8- {{ $t('tax_report.expenses_subject_to_the_basic_rate') }}</td>
            <td>
              <span style="width: 100%;text-align: center;">0.00</span>
            </td>
            <td>
              <span style="width: 100%;text-align: center;">0.00</span>
            </td>
            <td><span style="width: 100%;text-align: center;">0.00</span></td>
          </tr>
          <tr>
            <td>9- {{ $t('tax_report.imports_subject_to_vat_at_the_basic_rate_and_paid_at_customs') }}</td>
            <td>
              <span style="width: 100%;text-align: center;">0.00</span>
            </td>
            <td>
              <span style="width: 100%;text-align: center;">0.00</span>
            </td>
            <td><span style="width: 100%;text-align: center;">0.00</span></td>
          </tr>
          <tr>
            <td>10- {{ $t('tax_report.imports_subject_to_vat_to_which_the_reverse_charge_mechanism_applies') }}</td>
            <td>
              <span style="width: 100%;text-align: center;">0.00</span>
            </td>
            <td>
              <span style="width: 100%;text-align: center;">0.00</span>
            </td>
            <td>
              <span style="width: 100%;text-align: center;">0.00</span>
            </td>
          </tr>
          <tr>
            <td>11- {{ $t('tax_report.zero_rated_purchases') }}</td>
            <td>
              <span style="width: 100%;text-align: center;">{{purchase.zero_rated_domestic_purchase.invoice_total_after_tax ? purchase.zero_rated_domestic_purchase.invoice_total_after_tax : 0.00}}</span>
            </td>
            <td>
              <span style="width: 100%;text-align: center;">{{purchase.zero_rated_domestic_purchase.credit_invoice_total_after_tax ? purchase.zero_rated_domestic_purchase.credit_invoice_total_after_tax : 0.00}}</span>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>12- {{ $t('tax_report.exempt_purchases') }}</td>
            <td>
              <span style="width: 100%;text-align: center;">0.00</span>
            </td>
            <td>
              <span style="width: 100%;text-align: center;">0.00</span>
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="font-weight-bold">13- {{ $t('tax_report.total_purchases') }}</td>
            <td><span style="width: 100%;text-align: center;">{{sumAppliedAmountsPurchase}}</span></td>
            <td><span style="width: 100%;text-align: center;">{{sumCreditPurchase}}</span></td>
            <td><span style="width: 100%;text-align: center;">{{sumTaxPurchase}}</span></td>
          </tr>
          <tr>
            <td class="font-weight-bold">14- {{ $t('tax_report.total_vat_due_for_the_current_tax_period') }}</td>
            <td></td>
            <td></td>
            <td><span style="width: 100%;text-align: center;">0.00</span></td>
          </tr>
          <tr>
            <td>15- {{ $t('tax_report.corrections_from_previous_periods') }}</td>
            <td></td>
            <td></td>
            <td>
              <span style="width: 100%;text-align: center;">0.00</span>
            </td>
          </tr>
          <tr>
            <td>16- {{ $t('tax_report.vat_carried_forward_from_previous_period') }}</td>
            <td></td>
            <td></td>
            <td>
              <span style="width: 100%;text-align: center;">0.00</span>
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold">17- {{ $t('tax_report.net_tax_due') }}</td>
            <td></td>
            <td></td>
            <td>
              <span style="width: 100%;text-align: center;">{{TotalNetTax}}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'TaxDeclarationReport'"></report-main-header>
      </div>
    </div>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";

export default {
  name: "index-tax-declaration-report",
  components: {ReportMainHeader},
  data() {
    return {
      mainRoute: '/reports/taxes',
      mainRouteReport: '/reports/taxes',
      mainRouteDependency: 'base/dependency',



      tax_data_list: [],
      data_details: [],
      details_total: [],

      dataForExportDetails: [],
      dataTotalsForExportDetails: [],
      filters: {
        from_date: null,
        to_date: null,

        f_branches_ids: null,
        f_taxes_ids: null,
        f_currencies_ids: null,

        period: null,
        range: null,
        f_time_period: 0,
      },
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 1,
      taxes: [],
      branches_ids: null,
      branches: [],
      taxes_ids: {id: null, name: this.$t('all')},
      currencies: [],
      currency_default_id: null,

      object_data: {
        value_rate:	0,
        tax_values:	0,
        applied_amounts:	0,
        total_credit_tax_values:	0,
        total_credit_applied_amount:	0,
        invoice_total_after_tax:	0,
        credit_invoice_total_after_tax:	0,
      },

      keys_matching: {
        sales_subject_to_basic_rate:	15,
        zero_rated_domestic_sales:	0,
        purchase_subject_to_basic_rate:	15,
        zero_rated_domestic_purchase:	0,
      },
      sales:{
        sales_subject_to_basic_rate: {},
        zero_rated_domestic_sales: {},
      },
      purchase:{
        purchase_subject_to_basic_rate: {},
        zero_rated_domestic_purchase: {},
      },
    }
  },
computed:{
  sumAppliedAmountsSales: function (){
    let _objs = Object.keys(this.sales)
    return this.$_.sumBy(_objs, (_attr) => {
      return (this.sales[_attr].invoice_total_after_tax ? Math.abs(this.sales[_attr].invoice_total_after_tax) : 0);
    });
  },
  sumCreditSales: function (){
    let _objs = Object.keys(this.sales)
    return this.$_.sumBy(_objs, (_attr) => {
      return (this.sales[_attr].credit_invoice_total_after_tax ? Math.abs(this.sales[_attr].credit_invoice_total_after_tax) : 0);
    });
  },
  sumTaxSales: function (){
    let _objs = Object.keys(this.sales)
    return this.$_.sumBy(_objs, (_attr) => {
      return Math.abs(this.sales[_attr].tax_values);
    });
  },
  sumAppliedAmountsPurchase: function (){
    let _objs = Object.keys(this.purchase)
    return this.$_.sumBy(_objs, (_attr) => {
      return (this.purchase[_attr].invoice_total_after_tax ? Math.abs(this.purchase[_attr].invoice_total_after_tax) : 0);
    });
  },
  sumCreditPurchase: function (){
    let _objs = Object.keys(this.purchase)
    return this.$_.sumBy(_objs, (_attr) => {
      return (this.purchase[_attr].credit_invoice_total_after_tax ? Math.abs(this.purchase[_attr].credit_invoice_total_after_tax) : 0);
    });
  },
  sumTaxPurchase: function (){
    let _objs = Object.keys(this.purchase)
    return this.$_.sumBy(_objs, (_attr) => {
      return Math.abs(this.purchase[_attr].tax_values);
    });
  },
  TotalNetTax: function (){
    return Math.abs(this.sumTaxSales) - Math.abs(this.sumTaxPurchase);
  },

},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.accounting_reports'), route: '/reports/accounting-reports'}, {title: this.$t("MENU.tax_declaration_report")}]);
    this.defaultDataForUser();
    this.getCurrencies();
    this.getTaxes();
    this.getBranches();
    this.doFilter();
    },
  methods: {
    ...cssStypeForPrintReport,
    getRandom() {
      return Math.floor(Math.random() * 10000);
    },
    getIds(related_with) {
      switch (related_with) {
        case 'branches':
          this.filters.f_branches_ids = this.branches_ids.id;
          break;
        case 'taxes':
          this.filters.f_taxes_ids = this.taxes_ids.id;
          break;
      }
    },
    changeIntervalType(action_type, range = null) {
      if (this.filters.period == action_type) {
        this.filters.period = null;
        this.filters.f_time_period = 0;
      } else {
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

    },
    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getReportDetails();
    },
    doFilter() {
      this.page = 1;
      this.getReportDetails();

    },
    resetFilter() {
      this.filters.period = null;
      this.filters.range = null;
      this.filters.f_time_period = 0;
      this.filters.from_date = null;
      this.filters.to_date = null;

      this.filters.f_branches_ids = null;
      this.filters.f_taxes_ids = null;
      this.filters.f_currencies_ids = this.currency_default_id;
      this.taxes_ids = {id: null, name: this.$t('all')};
      this.branches_ids = null;

      this.doFilter();

    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.filters.f_currencies_ids = response.data.data.currency_id;
        this.currency_default_id = response.data.data.currency_id;
      });
    },

    getTaxes() {
      ApiService.get(`${this.mainRouteDependency}/taxes`).then((response) => {
        this.taxes = response.data.data;
        this.taxes.unshift({id: null, name: this.$t('all')});

      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },

    uniqueKey(){
      return  Math.floor(Math.random() * 10000000000) + '_'+ Date.now();
    },

    /*****
     * details
     */

    getReportDetails() {
      // let _limit = (this.page ? this.page : 1) * 25
      let _sales_key = Object.keys(this.sales);
      _sales_key.forEach((key) => {
        this.sales[key] = {...this.object_data};
      });

      let _purchase_key = Object.keys(this.purchase);
      _purchase_key.forEach((key) => {
        this.purchase[key] = {...this.object_data};
      });

      let _object_data = Object.keys(this.object_data);
      // this.sales = {
      //   sales_subject_to_basic_rate: {},
      //   zero_rated_domestic_sales: {},
      // };
      // this.purchase = {
      //   purchase_subject_to_basic_rate: {},
      //   zero_rated_domestic_purchase: {},
      // },
      ApiService.get(this.mainRouteReport, {params: {...this.filters, only_vat: 1 }}).then((response) => {
        if(response.data.data.data['Sales'] && response.data.data.data['Sales'].items){
          response.data.data.data['Sales'].items.forEach((item) => {
            _sales_key.forEach((key) => {
              _object_data.forEach((attr) => {
                if (this.keys_matching[key] == item.value_rate)
                  this.sales[key][attr] = item[attr] ? item[attr] : 0;
              });
            });
          });
        }

        if(response.data.data.data['Purchases'] && response.data.data.data['Purchases'].items){
          response.data.data.data['Purchases'].items.forEach((item) => {
            _purchase_key.forEach((key) => {
              _object_data.forEach((attr) => {
                if (this.keys_matching[key] == item.value_rate)
                  this.purchase[key][attr] = item[attr] ? item[attr] : 0;
              });
            });
          });
        }


        this.tax_data_list = response.data.data;
      });
    },
    printData(tableId) {
      this.setReportLog('pdf', 'tax declaration report');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },

    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'tax declaration report');
      setTimeout(() => {
        TableToExcel.convert(document.getElementById(tableId), {
          name: this.$t('MENU.tax_declaration_report') + '.xlsx',
          sheet: {
            name: 'Sheet 1'
          }
        });
      }, 100)
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }

  },
};
</script>
<style scoped>
.bg-E4A102{
  background-color: #E4A102 !important;
  color: #fff !important;
  font-weight: bold !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
</style>
